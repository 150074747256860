import { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import ApiCalls from "../../api/index";
import "../../App.css";
import Header from "../../components/header";
import { useEffect } from "react";


export default function Login() {
  const {state} = useLocation();
  const [currentPath, setCurrentPath] = useState(state);
  const [customer, setCustomer] = useState({
    username: "",
    email: "",
    password: "",
    subscription_end_date: "",
  });
  const [alert, setAlert] = useState({status: "", message: ""});

  useEffect(() => {
    window.history.replaceState({}, document.title)
    setTimeout(() => {
      setCurrentPath();
    }, 3000);
  }, []);

  

  const handleChangeCustomer = (event, fieldName) => {
    if (fieldName === "username")
      setCustomer((prevState) => {
        return { ...prevState, username: event.target.value };
      });
    if (fieldName === "domain")
      setCustomer((prevState) => {
        return { ...prevState, domain: event.target.value };
      });
    if (fieldName === "password")
      setCustomer((prevState) => {
        return { ...prevState, password: event.target.value };
      });
    if (fieldName === "subscription_end_date")
      setCustomer((prevState) => {
        return { ...prevState, subscription_end_date: event.target.value };
      });
  };

  const navigate = useNavigate();

  // login
  const handleSubmit = () => {
    console.log("customer");
    console.log(customer);
    ApiCalls.post(`/api/auth/login`, { username: customer.username, password: customer.password })
    .then(res => {
        setAlert({status: 'success', message: res.data.message});
        const  data = res.data;
        localStorage.setItem("token", data.token);
        localStorage.setItem("username", data.data.username);
        if(data.data.is_admin === false) {
          localStorage.setItem("is_admin", false);
          //getConsumer(data.data.user.customer_secret);
          localStorage.setItem("consumer_id", data.data.user.customer_secret);
          window.open(`/admin/customer/edit/${data.data.user.customer_secret}`,  "_self");
        } else {
          localStorage.setItem("is_admin", true);
          //navigate("/admin/dashboard");
          window.open("/admin/dashboard", "_self");
        }
    })
    .catch(err => {
        setAlert({status: 'error', message: err.response.data.error});
    });
    
  };

const getConsumer = (user_id) => {
  ApiCalls.get(`/api/jobs/${user_id}`).then((res) => {
    if (res) {
      const consumer_id = res.data?.data?.customer?.consumer_id;
    
      if(localStorage.getItem("is_admin"=== true)) {
        navigate("/admin/dashboard");
      }else {
        localStorage.setItem("consumer_id", user_id);
        window.open(`/admin/customer/edit/${user_id}`,  "_self");
      }
    }
  });
}

  return (
    <>
        <Header/>
        <div class="coming-soon">
        <div className="container" style={{marginTop: '30px', paddingTop: '20px'}}>
      <div class="row">
        
        
        <div
          class="panel panel-color panel-primary panel-pages"
          style={{ width: "30%", marginLeft: "auto", marginRight: "auto", marginTop: "10%" }}
        >
          
          
        

          <div class="panel-body">
            <h3 class="text-center m-t-0 m-b-30" style={{display:'none'}}>
              {" "}
              <span class="">
                <img src="/assets/images/big/bigb.png" alt="logo" style={{height :"auto", maxWidth : "170px"}} />
              </span>
            </h3>
            <h4 class="text-muted text-center m-t-0">
              <b>Sign In</b>
            </h4>
            <form class="form-horizontal m-t-20">
            <div  style={{ display: "none"}}  
                    className={` 
                      ${ alert.status =="success"? "alert alert-success show": "" } 
                      ${ alert.status =="error"? "alert alert-danger show": "" }`}
              >
                <span>{alert.message}</span>
        </div>
              <div class="form-group">
                <div class="col-xs-12">
                  <input
                    class="form-control"
                    style={{fontSize:'14px'}}
                    type="email"
                    required=""
                    placeholder="Email"
                    value={customer.username}
                    onChange={(event) =>
                      handleChangeCustomer(event, "username")
                    }
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="col-xs-12">
                  <input
                    class="form-control"
                    style={{fontSize:'14px'}}
                    type="password"
                    required=""
                    placeholder="Password"
                    value={customer.password}
                    onChange={(event) =>
                      handleChangeCustomer(event, "password")
                    }
                  />
                </div>
              </div>
              <div class="form-group">
                <div className="row inline-form">
                <div class="col-xs-2">
                  <div class="checkbox checkbox-primary" style={{paddingLeft:'30px', fontSize:'14px'}}>
                    <input id="checkbox-signup" type="checkbox" style={{width:'25px'}} />
                    <label for="checkbox-signup"> Remember me: </label> &nbsp;&nbsp;
                    <Link style={{textDecoration:'underline', paddingLeft:'90px'}} to="/forgot-password">Forgot Password</Link>
                  </div>
                </div>
                <div class="col-xs-2" style={{marginTop: "7px", marginLeft: "280px"}}>
                    <Link style={{textDecoration:'underline'}} to="/register">Sign Up</Link>
                </div>
                </div>

              </div>
              <div class="form-group text-center m-t-20">
                <div class="col-xs-12">
                  {" "}
                  <button type="button"
                    class="btn btn-primary w-md waves-effect waves-light"
                    style={{fontSize:'17px'}}
                    onClick={handleSubmit}
                  >
                    Log In
                  </button>
                </div>
              </div>
              <div class="form-group m-t-30 m-b-0">
                <div class="col-sm-7"></div>
                <div class="col-sm-5 text-right"> </div>
              </div>
            </form>
          </div>
        </div>

      </div>
    </div>
		</div>
    </>
  );
}
