import Table from 'react-bootstrap/Table';
import * as React from "react";
import {useEffect, useState} from "react";
import moment from "moment";
import ReactPaginate from "react-paginate";
import ApiCalls from "../../../api";
import {useNavigate} from "react-router-dom";

export default function PublicJobs(props) {
    let navigate = useNavigate();

    const [jobs, setJobs] = useState([]);
    const [paginationData, setPaginationData] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const itemsPerPage = 10;

    useEffect(() => {

    }, []);

    useEffect(() => {
        searchJobs();
    }, [itemOffset, itemsPerPage]);

    const searchJobs = () => {
        var url = '/api/public/jobs';
        ApiCalls.get(url).then(res => {
            const {data} = res.data;
            setPaginationData(data);
            const endOffset = itemOffset + itemsPerPage;
            setPageCount(Math.ceil(data.length / itemsPerPage));
            setJobs(data.slice(itemOffset, endOffset));
        }).catch(err => {

        });
    }
    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % paginationData.length;
        console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
    };

    const applyJob = (job_id) => {
        navigate(`/jobs/apply/${job_id}`);
    }
    return (
        <>
            <section>
                <div style={{paddingTop: '15px'}}>
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                    <h3 className="panel-title">Jobs Listing</h3>
                                </div>
                                <div className="panel-body" style={{paddingBottom: '5px'}}>
                                    <Table striped hover size="xl">
                                        <thead>
                                        <tr>
                                            <th>Job Title</th>
                                            <th>Ref No</th>
                                            <th>Company</th>
                                            <th className="text-center">Posted Date</th>
                                            <th className="text-center">Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {jobs.length < 1 &&
                                        <tr>
                                            <td colSpan={7} className='text-center'>No Jobs found.</td>
                                        </tr>
                                        }
                                        {jobs.map(job => {
                                            return (
                                                <>
                                                    <tr>
                                                        <td style={{textTransform: 'capitalize'}}>{job.title}</td>
                                                        <td>{job.job_reference_number}</td>
                                                        <td style={{textTransform: 'capitalize'}}>{job.company_name}</td>
                                                        <td className="text-center">{moment(job.updated_at).format("MMM DD, YYYY")}</td>
                                                        <td className='text-center'>
                                                            <a target="_blank" href={job.url}
                                                               className="btn btn-secondary"
                                                               style={{marginLeft: "10px"}} title="View">View</a>
                                                            <a href={"/jobs/apply/"+job.id}
                                                               className="btn btn-success"
                                                               style={{marginLeft: "10px"}} title="Apply">Apply</a>
                                                        </td>
                                                    </tr>
                                                </>
                                            )
                                        })}
                                        </tbody>
                                    </Table>
                                    <ReactPaginate
                                        className="pagination pull-right"
                                        breakLabel="..."
                                        nextLabel="Next >"
                                        onPageChange={handlePageClick}
                                        pageRangeDisplayed={5}
                                        pageCount={pageCount}
                                        previousLabel="< Previous"
                                        activeClassName={"active"}
                                        renderOnZeroPageCount={null}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}