import { useState } from "react";
import { Link, useNavigate, useLocation, useSearchParams } from "react-router-dom";
import ApiCalls from "../../api/index";
import "../../App.css";
import Header from "../../components/header";
import { useEffect } from "react";
import BarLoader from "react-spinners/BarLoader";
import { css } from "@emotion/react";

export default function VerifyActivationEmail() {
  const {state} = useLocation();
  const [ isLoading, setIsLoading ] = useState(true);
  const [ isEmailVerified, setIsEmailVerified ] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const [color, setColor] = useState("#03a9f4");
  const [customer, setCustomer] = useState({
    username: "",
    email: "",
    password: "",
    subscription_end_date: "",
  });
  const [alert, setAlert] = useState({status: "", message: ""});

  const override = css`
  display: block;
  margin: 0 auto;
  border-color: #03a9f4;
`;

  useEffect(() => {
    window.history.replaceState({}, document.title);
    console.log(searchParams.get('token'));
    const token = searchParams.get('token');
    ApiCalls.get(`/api/email-verify?token=${token}`).then((res) => {
      setIsLoading(false);
      if (res.status === 200) {              
        console.log(res);
        setIsEmailVerified(true);
        setAlert({status: 'success', message: res.data.message});
      }
      else {
        console.log(res);
        setIsEmailVerified(false);
        setAlert({status: 'error', message: res.data.error});
      }     
    });
  }, []); 

  

  const navigate = useNavigate();

  // login
  const handleSubmit = () => {
    console.log(customer);
    ApiCalls.post(`/api/auth/login`, { username: customer.username, password: customer.password }).then((res) => {
      if (res) {
        setAlert({status: 'success', message: "Successfully logged in."});
        const  data = res.data;
        localStorage.setItem("token", data.token);
        localStorage.setItem("username", data.data.username);
        if(data.data.is_admin === false) {
          localStorage.setItem("is_admin", false);
          //getConsumer(data.data.user.customer_secret);
          localStorage.setItem("consumer_id", data.data.user.customer_secret);
          window.open(`/admin/customer/edit/${data.data.user.customer_secret}`,  "_self");
        } else {
          localStorage.setItem("is_admin", true);
          //navigate("/admin/dashboard");
          window.open("/admin/dashboard", "_self");
        }
        
      }
      else
        setAlert({status: 'error', message: "Wrong credentials."});
    });
    
  };

const getConsumer = (user_id) => {
  
}

  return (
    <>
        <Header/>
        <div class="coming-soon">
        <div className="container" style={{marginTop: '30px', paddingTop: '20px'}}>
      <div class="row">
        
        
        <div
          class="panel panel-color panel-primary panel-pages"
          style={{ width: "30%", marginLeft: "auto", marginRight: "auto", marginTop: "10%" }}
        >
          
          
        

          <div class="panel-body">
          <BarLoader color={color} loading={isLoading} css={override} size={750} />
            <h3 class="text-center m-t-0 m-b-30" style={{display:'none'}}>
              {" "}
              <span class="">
                <img src="/assets/images/big/bigb.png" alt="logo" style={{height :"auto", maxWidth : "170px"}} />
              </span>
            </h3>
            { isLoading && 
              <h4 class="text-muted text-center m-t-0">
                <br/>
                <b>Checking & Verifying your account</b>
              </h4>
            }
            <form class="form-horizontal m-t-20">
            <div  style={{ display: "none"}}  
                    className={` 
                      ${ alert.status =="success"? "alert alert-success show": "" } 
                      ${ alert.status =="error"? "alert alert-danger show": "" }`}
              >
                <span>{alert.message}</span>
        </div>                            
              <div class="form-group">
                <div className="row inline-form">         
                { isEmailVerified &&        
                  <div class="col-xs-2" style={{marginTop: "7px", marginLeft: "150px", marginTop: "30px"}}>
                      <Link style={{textDecoration:'underline'}} to="/login">Login</Link>
                  </div>
                }
                </div>

              </div>              
              <div class="form-group m-t-30 m-b-0">
                <div class="col-sm-7"></div>
                <div class="col-sm-5 text-right"> </div>
              </div>
            </form>
          </div>
        </div>

      </div>
    </div>
		</div>
    </>
  );
}
