import * as React from "react";
import ApiCalls from "../api/index";
import "../App.css";
import moment from "moment";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  gridClasses,
} from "@mui/x-data-grid";
import XMLViewer from "react-xml-viewer";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { useEffect } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  maxHeight: "400px",
  minWidth: "800px",
  overflowX: "auto !important",
  overflowY: "auto !important",
};

export default function HomePage(props) {
  const [logs, setLogs] = React.useState([]);
  const [xmlData, setXmlData] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  useEffect(() => {
    /*const collection = document.getElementsByClassName("topbar");
    const elementfooter = document.getElementsByClassName("footer");
    const elementContent = document.getElementsByClassName("content-page");
    collection[0].style.display = "block";
    elementfooter[0].style.display = "block";
    elementContent[0].style.marginLeft = "240px";*/
  }, []);
  useEffect(() => {
    /*ApiCalls.get(`/api/logs`).then((res) => {
      if (res.ok) {
        const { data } = res.data;
        setLogs(data);
        forceUpdate();
      }
    });*/
  }, []);
  const handleModalOpen = (row) => {
    setXmlData(row);
    setOpen(true);
  };
  const showModalWithResponseXML = (params) => {
    return (
      <strong>
        <a
          style={{ cursor: "pointer" }}
          onClick={() => handleModalOpen(params.row.responsePayload)}
        >
          View
        </a>
      </strong>
    );
  };
  const showModalWithRequestXML = (params) => {
    return (
      <strong>
        <a
          style={{ cursor: "pointer" }}
          onClick={() => handleModalOpen(params.row.requestPayload)}
        >
          View
        </a>
      </strong>
    );
  };

  function IsJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  function returnBeautifyString(str) {
    try {
      const a = JSON.parse(str);
      return JSON.stringify(a, null, 2);
    } catch (e) {
      return str;
    }
  }

  const handleClose = () => setOpen(false);
  const columns = [
    { field: "id", headerName: "ID", width: 100 },
    {
      field: "name",
      headerName: "Job board",
      width: 150,
    },
    {
      field: "timestamp",
      headerName: "Posted AT",
      width: 300,
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
    },
    {
      field: "requestPayload",
      headerName: "Request Payload",
      renderCell: showModalWithRequestXML,
      width: 150,
      sortable: false,
    },
    {
      field: "responsePayload",
      headerName: "Response Payload",
      renderCell: showModalWithResponseXML,
      width: 150,
      sortable: false,
    },
  ];

  function getStatusText(status) {
    if (status === "C") {
      return "Completed";
    } else if (status === "R") {
      return "Rejected";
    } else if (status === "P") {
      return "Pending";
    }
  }
  function getRows(logs) {
    if (logs && logs.length > 0) {
      return logs.map(function (log) {
        return {
          id: log.log_id,
          name: log.name,
          status: getStatusText(log.status),
          timestamp: moment(log.request_timestamp).format(
            "DD-MMM-YYYY HH:mm:ss"
          ),
          requestPayload: log.request_payload,
          responsePayload: log.response_payload,
        };
      });
    } else {
      return [];
    }
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport
          csvOptions={{ fields: ["id", "name", "timestamp", "status"] }}
        />
      </GridToolbarContainer>
    );
  }
  return (
    <>
    <div class="row">
                            <div class="col-md-12">
                                <div class="panel panel-primary">
                                    <div class="panel-heading">
                                        <h3 class="panel-title">Dashboard</h3>
                                    </div>
                                    <div class="panel-body">
                                        <div class="row">
                                            <div class="col-xs-12">
                                                <div class="">
                                                
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
    
    <div className="table-responsive" style={{ height: "auto", display:'none' }}>
      <DataGrid
        rows={getRows(logs)}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        autoHeight={true}
        components={{
          Toolbar: CustomToolbar,
        }}
        disableSelectionOnClick
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {IsJsonString(xmlData) ? (
            <pre>{returnBeautifyString(xmlData)}</pre>
          ) : (
            <XMLViewer xml={xmlData} />
          )}
        </Box>
      </Modal>
    </div>
    </>
  );
}
