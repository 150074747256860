import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as JobChoices from "../Services/JobChoices";
import ApiCalls from "../api/index";
import "../App.css";
import axios from 'axios';
import { useEffect, useState } from "react";

export default function EditJobsPostedBoard(props) {
  const [alert, setAlert] = useState({status: "", message: ""});
  const [ messageSent, setMessageSent ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(true);

  const [jobToboard, setJobToboard] = useState({ board_id:'', customer_id:'', job_id: '', request_payload: "", response_payload:'', transaction_did:'' });


  let navigate = useNavigate();

  const params = useParams();

  useEffect(() => { 
    ApiCalls.get(`/api/jobs-posted-boards/edit/${params.board_id}`)
    .then(res => {
      setIsLoading(false);
      var _job_posted_board = res.data.data;
      setJobToboard({ 
                    board_id:_job_posted_board.board_id, 
                    customer_id:_job_posted_board.customer_id, 
                    job_id:_job_posted_board.job_id, 
                    request_payload:_job_posted_board.request_payload,
                    response_payload:_job_posted_board.response_payload,
                    transaction_did:_job_posted_board.transaction_did
                });
    })
    .catch(err => {
        setAlert({status: 'error', message: err.response.data.error});
    });
  }, []);

  const handleChangeJobPosteBoard = (event, fieldName) => {

  	if (fieldName === "transaction_did")
      setJobToboard((prevState) => {
        return { ...prevState, transaction_did: event.target.value };
      });
    if (fieldName === "request_payload")
      setJobToboard((prevState) => {
        return { ...prevState, request_payload: event.target.value };
      });
    if (fieldName === "response_payload")
      setJobToboard((prevState) => {
        return { ...prevState, response_payload: event.target.value };
      });
  };

  const handleSubmitJobToBoard = () => {  	
    ApiCalls.post(`/api/jobs-posted-boards/update/${params.board_id}`, { board_id:jobToboard.board_id, customer_id:jobToboard.customer_id, job_id: jobToboard.job_id, request_payload: jobToboard.request_payload, response_payload:jobToboard.response_payload, transaction_did:jobToboard.transaction_did })
    .then(res => {
        const { data } = res.data;
        setMessageSent(true);
        console.log(data);
        navigate('/admin/jobs');
        setAlert({status: 'success', message: res.data.message});
    })
    .catch(err => {
        setAlert({status: 'error', message: err.response.data.error});
    });
   
  };


  return (
    <>
      <div class="panel panel-primary">
        <div class="panel-heading">
          <h3 class="panel-title">Edit Job Posted Board</h3>
        </div>
        {/*<div  style={{ display: "none", marginTop:"10px"}}  
          className={` 
          ${ alert.status =="success"? "alert alert-success show": "" } 
          ${ alert.status =="error"? "alert alert-danger show": "" }`}
          >
          <span>{alert.message}</span>
        </div>*/}
        <div class="panel-body">
          <form role="form">
          	<div class="row">
              <div class="col-md-3">
                <div className="form-group">
                  <label for="">Transaction DID</label>
                  <input class="form-control" 
                    style={{fontSize:'14px'}}
                    type="text"
                    required=""
                    placeholder="Transaction DID"
                    name="transaction_did"
                    id="transaction_did"
                    onChange={(event) =>
                      handleChangeJobPosteBoard(event, "transaction_did")
                    }   />
                </div>                  
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="">Request Payload</label>
                  <textarea class="form-control" cols="223" rows="50" type="text"  defaultValue={jobToboard.request_payload} onChange={(event) =>
                      handleChangeJobPosteBoard(event, "request_payload")
                    } />
                </div>                 
              </div>
            </div> 
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="">Response Payload</label>
                  <textarea class="form-control" cols="223" rows="50" type="text"  defaultValue={jobToboard.response_payload} onChange={(event) =>
                      handleChangeJobPosteBoard(event, "response_payload")
                    } />
                </div>                 
              </div>
            </div> 
            
            <button type="button" onClick={handleSubmitJobToBoard} class="btn btn-primary pull-right">Update</button>
          </form>
        </div>
      </div>
    </>
  );
}
