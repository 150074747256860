import * as React from "react";
import ApiCalls from "../api/index";
import "../App.css";
import moment from "moment";
import { useState } from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  gridClasses,
} from "@mui/x-data-grid";
import XMLViewer from "react-xml-viewer";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  maxHeight: "400px",
  minWidth: "600px",
  overflowX: "auto !important",
  overflowY: "auto !important",
};

export default function HomePage(props) {
  const [customers, setCustomers] = React.useState([]); 
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [alert, setAlert] = useState({status: "", message: ""});

  useEffect(() => {
    ApiCalls.get(`/api/customers`).then((res) => {
      if (res) {
        const { data } = res.data;
        setCustomers(data);
        forceUpdate();
      }
    });
  }, []);

  useEffect(() => {
    if ( !localStorage.getItem('token') )
      navigate("/login");
  }, []);    

  const goToCustomerDetailsIdColumn = (customer_secret) => {
    navigate(`/admin/customers/${customer_secret}`);
  };  

  const editCustomerDetail = (customer_secret) => {
    navigate(`/admin/customer/edit/${customer_secret}`);
  }
  const handleDeleteCustomer = (customer_secret) => {
    if (window.confirm("Are you sure want to delete?")){    
      //Logic to delete the job
      ApiCalls.delete(`/api/customers/delete/${customer_secret}`).then((res) => {        
        if (res) {
         // const { data } = res.data;                    
         setCustomers(
          customers.filter( customer => customer.customer_secret !== customer_secret)
          );  
          setAlert({status: 'success', message: "Customer deleted successfully!"});  
          setTimeout(() => {
            setAlert({status: "", message: ""})
          }, 2000);       
        }
      });
    }
    // setCustomerBoards(
    //   customerBoards.filter((_board, _index) => _index !== index)
    // );
  }

  const handleActivateDeactivateCustomer = (customer_id, is_verified) => {
    console.log(customer_id, is_verified);
    
      //Logic to change customer status
      ApiCalls.post(`/api/customers/update-status/${customer_id}`, {is_verified: is_verified===1?0:1} ).then((res) => {        
        if (res.status === 200) {
          setCustomers(
            customers.map((_customer) => {
                return _customer.id === customer_id ? { ..._customer, is_verified: is_verified===1?0:1 } : _customer;
            })
          );  
          setAlert({status: 'success', message: "Customer updated successfully!"});  
          setTimeout(() => {
            setAlert({status: "", message: ""})
          }, 2000);     
        }
      });
        
  }

  let navigate = useNavigate();
  const goToAddCustomer = () => {
    navigate("/admin/add-customer");
  };

  function getStatusText(is_verified) {
    if (is_verified === 1) {
      return "Active";
    } 
    else if (is_verified === 0) {
      return "Inactive";
    } 
    // else if (is_verified === "P") {
    //   return "Pending";
    // }
  }

  
  return (
    <div>
    <div class="row">
                            <div class="col-md-12">
                                <div class="panel panel-primary">
                                <button onClick={goToAddCustomer} style={{marginTop:'20px', marginBottom:'20px', marginLeft: '15px' }} className="btn btn-primary">Add Customer</button>
                                <div  style={{ display: "none", marginTop:"10px"}}  
                                              className={` 
                                                ${ alert.status =="success"? "alert alert-success show": "" } 
                                                ${ alert.status =="error"? "alert alert-danger show": "" }`}
                                        >
                                        <span>{alert.message}</span>
                                  </div>
                                    <div class="panel-heading">
                                        <h3 class="panel-title">Customers</h3>
                                    </div>                                    
                                    <div class="panel-body">
                                        <div class="row">
                                            <div class="col-xs-12">
                                                <div class="table-responsive">
                                                      <table id="datatable" class="table table-striped table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>Name</th>
                                                                <th>Customer ID</th>
                                                                <th>Active Jobs</th>                                                                
                                                                <th class="text-center">Status</th>
                                                                <th>Subscription End Date</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {customers.map( customer => {
                                                          return ( 
                                                            <>
                                                            <tr>
                                                              <td className="td-class-hover-link" onClick={ () => goToCustomerDetailsIdColumn(customer.customer_secret) } style={{textTransform:'capitalize', cursor: 'pointer'}}>{ customer.username }</td>
                                                                <td style={{cursor: 'pointer'}} className="td-class-hover-link" onClick={ () => goToCustomerDetailsIdColumn(customer.customer_secret) }>{ customer.customer_secret }</td> 
                                                                <td class="text-center" style={{ width:"100px"}}><span class="badge badge-light">{customer.job_count}</span></td>                                                               
                                                                <td class="text-center" style={{ width:"200px"}}>
                                                                    {/*<span style={{cursor: 'pointer'}} title="Update Status" onClick={() => handleActivateDeactivateCustomer(customer.id, customer.is_verified)}  className={`label ${customer.is_verified === 1 ?'label-success' :'label-dark'}`}>{ getStatusText(customer.is_verified)}</span>*/}
                                                                    <label className="switch">
                                                                        <input
                                                                            type="checkbox"
                                                                            onClick={() => handleActivateDeactivateCustomer(customer.id, customer.is_verified)}
                                                                            checked={customer.is_verified}
                                                                        />
                                                                        <span className="slider"></span>
                                                                    </label>
                                                                </td>
                                                                <td>{moment(customer.subscription_end_date).format("MMM DD, YYYY")}</td>
                                                                <td>
                                                                  <a onClick={ () => goToCustomerDetailsIdColumn(customer.customer_secret) } style={{cursor: 'pointer'}} title="View"><i class="ti-eye"></i></a> 
                                                                  <a onClick={ () => editCustomerDetail(customer.customer_secret) } style={{marginLeft:"10px", cursor: 'pointer'}} title="Edit"><i class="ti-pencil"></i></a> 
                                                                  <a  style={{ marginLeft:"10px", cursor: 'pointer'}} onClick={() => handleDeleteCustomer(customer.customer_secret)} title="Delete"><i class="ti-trash"></i></a>
                                                                </td>                                                        
                                                            </tr>
                                                            </>
                                                          )
                                                        })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 

    
    </div>
  );
}
