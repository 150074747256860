import React, { Fragment } from "react";
import PostJob from "../pages/PostJob";
import JobPage from "../pages/JobPage";
import { BrowserRouter as Router, useRoutes, Route, Routes } from "react-router-dom";
import JobsPage from "../pages/JobsPage";
import EditJobPage from "../pages/EditJobPage";
import EditJobsPostedBoard from "../pages/EditJobsPostedBoard";
import JobsLogsPage from "../pages/JobsLogsPage";
import DashboardPage from "../pages/DashboardPage";
import JobBoardsList from "../pages/JobBoardsList";
import AddJobBoard from "../pages/AddJobBoard";
import CustomersList from "../pages/CustomersList";
import AddCustomer from "../pages/AddCustomer";
import EditCustomer from "../pages/EditCustomer";
import EditBoard from "../pages/EditBoard";
import Login from "../pages/Login";
import CustomerLogin from '../pages/customerAuth/Login';
import ForgotPassword from '../pages/customerAuth/ForgotPassword';
import ResetPassword from '../pages/customerAuth/ResetPassword';
import RegisterRegister from "../pages/customerAuth/Register";
import VerifyActivationEmail from '../pages/customerAuth/VerifyEmail';
import RequestDemo from "../pages/public/RequestDemo";
import CustomerDetails from "../pages/CustomerDetails";
import JobDetails from "../pages/JobDetails";
import MainAdmin from '../containers/layouts/admin';
import Home from '../pages/public/Home';
import ChangePassword from '../pages/ChangePassword';
import PublicJobs from '../pages/public/jobs/Index';
import PublicJobApply from '../pages/public/jobs/Apply';

const AppWrapper = () => {
    return (
        <Router>
            <Routes>
                <Route exact path="/" element={<Home/>}/>
                <Route exact path="/admin/login" element={<Login/>}/>
                <Route exact path="/login" element={<CustomerLogin/>}/>
                <Route exact path="/forgot-password" element={<ForgotPassword/>}/>
                <Route exact path="/reset-password" element={<ResetPassword/>}/>
                <Route exact path="/register" element={<RegisterRegister/>}/>
                <Route exact path="/email-verify" element={<VerifyActivationEmail/>}/>
                <Route exact path="/request-a-demo" element={<RequestDemo/>}/>
                <Route path="/jobs" element={<PublicJobs/>}/>
                <Route path="/jobs/apply/:job_id" element={<PublicJobApply/>}/>
                <Route path="/admin" element={<MainAdmin/>}>
                    <Route path='dashboard' exact element={<DashboardPage/>}/>
                    <Route path='jobs' element={< JobsPage/>}/>
                    <Route path='jobs-logs' element={< JobsLogsPage/>}/>
                    <Route path='jobs/:job_id' element={< JobDetails/>}/>
                    <Route path='jobs/edit/:job_id' element={< EditJobPage/>}/>
                    <Route path='job-posted-board/edit/:board_id' element={< EditJobsPostedBoard/>}/>
                    <Route path='customers' element={<CustomersList/>}/>
                    <Route path='add-customer' element={< AddCustomer/>}/>
                    <Route path='customer/edit/:customer_secret' element={< EditCustomer/>}/>
                    <Route path='boards/edit/:board_id' element={< EditBoard/>}/>
                    <Route path='customer' element={<CustomersList/>}/>
                    <Route path='job-boards' element={< JobBoardsList/>}/>
                    <Route path='add-job-board' element={< AddJobBoard/>}/>
                    <Route path='customers/:customer_secret' element={< CustomerDetails/>}/>
                    <Route path='change-password' element={< ChangePassword/>}/>
                </Route>
            </Routes>
        </Router>
    );
};

class RootRoutes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
        };
    }

    render() {
        return <AppWrapper/>;
    }
}

export default RootRoutes;
