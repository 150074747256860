import axios from "axios";
import { useNavigate } from "react-router-dom";
import { create } from "apisauce";
let baseURL = process.env.REACT_APP_BASE_URL;


const api = axios.create({
  baseURL,
  // Increase timeout to 10 minutes
  timeout: 600000,
  
});

// Add a request interceptor
api.interceptors.request.use(
  async (config) => {
    // Do something before request is sent
    config.headers.common["Content-Type"] = "application/json";
    config.headers.common["Authorization"] = localStorage.getItem("token");

    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const status = error.response ? error.response.status : null;
    if (status === 401) {
      signOut();
    }
    return Promise.reject(error);
  }
);

const signOut = async () => {
  window.location.href = "/login";
};

export default class ApiCalls {
  static post(url, payload) {
    return api.post(url, payload).then((response) => {
      return response;
    });
  }

  static patch(url, payload) {
    return api.patch(url, payload).then((response) => {
      return response;
    });
  }

  static get(url) {
    return api.get(url).then((response) => {
      return response;
    });
  }

  static delete(url) {
    return api.delete(url).then((response) => {
      return response;
    });
  }
}
