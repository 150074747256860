import React from 'react'
import { Link } from "react-router-dom";

export default function Header() {
  return (
    <>
    	<header class="header-section transparent-header marketing-bg">
			<div class="header-area">
				<div class="container">
					<div class="primary-menu">
						<div class="logo">
							<Link to="/"><img src="temp-assets/images/logo/bean-bagv2.png" style={{width:'216px'}} alt="logo" /></Link>
						</div>
						<div class="main-area">
							<div class="main-menu">
							<ul>
                                    <li class=""><a href="/">Home</a>        
                                    </li>
                                    <li class=""><a href="#">How It Works</a></li>
                                    <li class=""><a href="#">Job Boards</a></li>
                                    <li class=""><a href="#">Clients</a></li>                                    
                                    <li class=""><a href="#">About Us</a></li>
                                    <li><a href="/login">Login</a></li>
                                    
                                </ul>
							</div>
							<div class="header-btn">
								<Link to="/request-a-demo" style={{fontSize:'16px', padding:'16px 25px'}} class="btn btn-primary w-md waves-effect waves-light"><span>Request a Demo</span></Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>
    </>
  )
}
