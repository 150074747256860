import * as React from "react";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import * as Yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import ApiCalls from "../../../api";

export default function PublicJobApply(props) {
    let navigate = useNavigate();

    const [alert, setAlert] = useState({status: "", message: ""});
    const [selectedFile, setSelectedFile] = useState(null);

    const validationSchema = Yup.object().shape({
        first_name: Yup.string().trim().required("First Name is required"),
        last_name: Yup.string().trim().required("Last Name is required"),
        email: Yup.string().required("Email is required"),
        phone: Yup.string().trim().required("Phone is required"),
    });
    const {
        register,
        handleSubmit,
        setValue: setValue,
        formState: {errors, isValid},
    } = useForm({
        resolver: yupResolver(validationSchema),
        mode: "onChange"
    });

    useEffect(() => {

    }, []);

    const onSubmit = async (data) => {
        handleSubmitJob(data);
    };

    const params = useParams();
    const handleSubmitJob = (data) => {
        const formData = new FormData();
        formData.append("resume", selectedFile);
        formData.append("first_name", data.first_name);
        formData.append("last_name", data.last_name);
        formData.append("email", data.email);
        formData.append("phone", data.phone);
        console.log(formData)
        ApiCalls.post(`/api/public/jobs/apply/${params.job_id}`, formData)
            .then(res => {
                const {data} = res.data;
                console.log(data);
                navigate('/jobs');
                setAlert({status: 'success', message: res.data.message});
            })
            .catch(err => {
                setAlert({status: 'error', message: err.response.data.error});
            });
    };

    const navigateBack = () => {
        navigate(`/jobs`)
    }
    return (
        <>
            <section>
                <div style={{paddingTop: '15px'}}>
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                    <h3 className="panel-title">Job Apply</h3>
                                </div>
                                <div className="panel-body" style={{paddingBottom: '5px'}}>
                                    <form role="form" onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
                                        <div className="row">
                                            <div className="col-xs-6">
                                                <div className="form-group">
                                                    <label htmlFor="first_name">First Name <span
                                                        className="red" style={{color: "red"}}>*</span></label>
                                                    <input type="text"
                                                           name="first_name"
                                                           id="first_name"
                                                           placeholder=""
                                                           required="required"
                                                           className={`form-control input-group m-b-0 ${
                                                               errors.first_name ? "is-invalid" : ""
                                                           }`}
                                                           {...register("first_name")}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xs-6">
                                                <div className="form-group">
                                                    <label htmlFor="first_name">Last Name <span
                                                        className="red" style={{color: "red"}}>*</span></label>
                                                    <input type="text"
                                                           name="last_name"
                                                           id="last_name"
                                                           placeholder=""
                                                           required="required"
                                                           className={`form-control input-group m-b-0 ${
                                                               errors.last_name ? "is-invalid" : ""
                                                           }`}
                                                           {...register("last_name")}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-xs-6">
                                                <div className="form-group">
                                                    <label htmlFor="email">Email address <span
                                                        className="red" style={{color: "red"}}>*</span></label>
                                                    <input type="email"
                                                           name="email"
                                                           id="email"
                                                           placeholder=""
                                                           required="required"
                                                           className={`form-control input-group m-b-0 ${
                                                               errors.email ? "is-invalid" : ""
                                                           }`}
                                                           {...register("email")}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xs-6">
                                                <div className="form-group">
                                                    <label htmlFor="phone">Phone <span className="red"
                                                                                       style={{color: "red"}}>*</span></label>
                                                    <input type="tel"
                                                           name="phone"
                                                           id="phone"
                                                           placeholder=""
                                                           required="required"
                                                           className={`form-control input-group m-b-0 ${
                                                               errors.phone ? "is-invalid" : ""
                                                           }`}
                                                           {...register("phone")}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-xs-6">
                                                <div className="form-group">
                                                    <label htmlFor="resume">Resume <span
                                                        className="red" style={{color: "red"}}>*</span></label>
                                                    <input type="file"
                                                           name="resume"
                                                           id="resume"
                                                           placeholder=""
                                                           required="required"
                                                           className={`form-control input-group m-b-0 ${
                                                               errors.resume ? "is-invalid" : ""
                                                           }`}
                                                           {...register("resume")}
                                                           onChange={(e) => setSelectedFile(e.target.files[0])}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="pull-right">
                                            <a style={{background: "#808083", color: "#FFF", marginRight: "10px"}}
                                               className="btn btn-default" href={"/jobs"}>Back</a>
                                            <button type={"submit"} style={{background: "#C00000", marginRight: "10px"}}
                                                    className="btn btn-primary">Submit
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}