import * as React from "react";
import { useNavigate } from "react-router-dom";
import ApiCalls from "../api/index";
import { useState } from "react";
import "../App.css";

export default function HomePage(props) {
  const [ boardName, setBoardName ] = React.useState('');
  const [ apiUrl, setApiUrl ] = React.useState('');
  const [ boardType, setBoardType ] = React.useState('xml');
  const [alert, setAlert] = useState({status: "", message: ""});

  let navigate = useNavigate();

  const saveBoard = () => {    
    ApiCalls.post(`/api/job-boards/store`, { name: boardName, api_url: apiUrl, board_type: boardType })
    .then(res => {
        const { data } = res.data;
        console.log(data);
        navigate('/admin/job-boards');
        setAlert({status: 'success', message: res.data.message});
    })
    .catch(err => {
        setAlert({status: 'error', message: err.response.data.error});
    });
  }


  return (
    <>
    <div class="row">
                            
    <div class="col-md-6">
    <div class="panel panel-primary">
        <div class="panel-heading"><h3 class="panel-title">Add Job Board</h3></div>
        <div  style={{ display: "none", marginTop:"10px"}}  
                    className={` 
                      ${ alert.status =="success"? "alert alert-success show": "" } 
                      ${ alert.status =="error"? "alert alert-danger show": "" }`}
              >
                <span>{alert.message}</span>
        </div>
        <div class="panel-body">
        <form role="form">
      <div className="form-group">
          <label for="">Name</label>
          <input type="text" class="form-control" value={boardName} 
          onChange={(event) => setBoardName(event.target.value) }  />
      </div>
    <div class="form-group">
      <label for="">API URL</label>
      <input type="text" class="form-control" 
        value={apiUrl} 
        onChange={(event) => setApiUrl(event.target.value) } />
    </div>
    <div class="form-group">      
      <label for="">Board Type</label>
      <br/>
      <input type="radio" name="site_name" id="xml"
      checked="checked"
        value='xml' 
        onChange={(event) => setBoardType(event.target.value) } /> XML
        <br/>
      <input type="radio" name="site_name" id="api"
        value='api'
        onChange={(event) => setBoardType(event.target.value) } /> API
    </div>
  <br />
  <button type="button" onClick={saveBoard} class="btn btn-primary pull-right">Save</button>
  </form>
      </div>
      </div>
    </div>
    </div>
    </>
  );
}
