import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ApiCalls from "../api/index";
import "../App.css";
import moment from "moment";
import XMLViewer from "react-xml-viewer";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  maxHeight: "400px",
  minWidth: "800px",
  overflowX: "auto !important",
  overflowY: "auto !important",
};

export default function HomePage(props) {
  const [ customerBoards, setCustomerBoards ] = React.useState([]);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const userRole = localStorage.getItem("is_admin");
  const [alert, setAlert] = useState({status: "", message: ""});
  const [xmlData, setXmlData] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const [ job, setJob ] = useState({});

  let navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    ApiCalls.get(`/api/jobs/view/${params.job_id}`).then((res) => {
        console.log(res.data);
        const { data, job_posted_boards } = res.data;
        var job = data;
        job['job_posted_boards'] = job_posted_boards;
        setJob(job);                
        forceUpdate();
    });
  }, []);   

  const handleModalOpen = (row) => {
    setXmlData(row);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  function IsJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  function returnBeautifyString(str) {
    try {
      const a = JSON.parse(str);
      return JSON.stringify(a, null, 2);
    } catch (e) {
      return str;
    }
  }

  const getCareerBuilderBoardStatusResponse = (transaction_did) => {    
    ApiCalls.get(`/api/status/career-builder/${transaction_did}`).then((res) => {
      if (res.status === 200) {
        setXmlData(res.data.data.response);
        setOpen(true);
      }
    });
  }

  const editJobPostedBoard = (board_id) => {
    navigate(`/admin/job-posted-board/edit/${board_id}`);
  }
   
  return (
    <>
    <div className="container">
      {/* {userRole &&
        <button  onClick={editCustomerDetail} style={{marginBottom:'20px' }} className="btn btn-primary">Update Customer</button>
      } */}
        
        <div class="row">
                            
      <div class="col-md-12">
          <div class="panel panel-primary">
              <div class="panel-heading"><h3 style={{textTransform:'capitalize'}} class="panel-title">{ job.title ? job.title : '' }</h3></div>
                             

                <div  style={{ display: "none", marginTop:"10px", marginBottom: "0px"}}  
                    className={` 
                      ${ alert.status =="success"? "alert alert-success show": "" } 
                      ${ alert.status =="error"? "alert alert-danger show": "" }`}
              >
                <span>{alert.message}</span>
                </div>

              <div class="panel-body">
              <form role="form">        
        
              <div class="row" style={{display:'none'}}>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="col-xs-6" for="domain">Domain</label>
                      <input readOnly class="form-control" id="domain" type="text" value={job?.domain} />
                    </div>
                  </div>
                </div>
                <div class="row" style={{display:'none'}}>
                  <div class="col-md-6">
                    <div class="form-group">
                        <label class="col-xs-6">API Access Key</label>
                        <input readOnly class="form-control" type="text" 
                         value={job?.api_key} 
                         />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="col-xs-6" for="domain">Customer ID</label>
                      <input readOnly class="form-control" id="domain" type="text" value={job?.customer_secret} />
                    </div>
                  </div>
                </div>
              <div class="col-xs-12 form-group" style={{marginTop:'10px', paddingLeft:'0px', paddingRight:'0px'}}>
              <table id="datatable" class="table table-striped table-bordered">
                  <thead>
                      <tr>
                          <th>Job Board</th>
                          <th>Posted At</th>
                          <th>Request Payload</th>
                          <th>Response Payload</th> 
                          <th style={{ display: "none"}}  class="text-center">Action</th>                         
                      </tr>
                  </thead>
                  <tbody>
                  { job?.job_posted_boards?.length < 1 && 
                                                            <tr>
                                                                <td colSpan={5} className='text-center'>No Job Boards found.</td>
                                                            </tr>
                                                          }
                  {job?.job_posted_boards?.map( (board, index) => {
                    console.log("board", board);
                    return ( 
                        <>
                          <tr>
                                <td style={{textTransform:'capitalize', color:'black'}}>
                                    {board.board_name}
                                </td>                                   
                                <td>
                                    {moment(board.created_at).format("MMM DD, YYYY")}
                                </td>
                                <td class="text-center" style={{width:'150px', cursor: 'pointer'}}><a onClick={() => handleModalOpen(board.request_payload)} title="View Request Payload"><i class="ti-eye"></i></a></td>
                                <td class="text-center" style={{width:'150px', cursor: 'pointer'}}>{board.board_slug == 'career-builder'? <a onClick={() => getCareerBuilderBoardStatusResponse(board.transaction_did)} title="View Response Payload"><i class="ti-eye"></i></a> : <a onClick={() => handleModalOpen(board.response_payload)}  title="View Response Payload"><i class="ti-eye"></i></a> }</td>
                                <td class="text-center" style={{width:'150px', cursor: 'pointer', display: "none"}}>
                                  <a style={{ cursor: 'pointer'}} onClick={ () => editJobPostedBoard(board.id) } title="Edit"><i class="ti-pencil"></i></a>                                </td>
                          </tr>
                          
                        </>                  
                    )
                  })}  
                  </tbody>
                </table>
                  
              </div>
          </form>
          </div>
          </div>
          </div>
    </div>    
    </div>

    <div className="table-responsive" style={{ height: "auto", display:'none' }}>      
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {IsJsonString(xmlData) ? (
            <pre>{returnBeautifyString(xmlData)}</pre>
          ) : (
            <XMLViewer xml={xmlData} />
          )}
        </Box>
      </Modal>
    </div>
    </>
  );
}
