import * as React from "react";
import { useState } from 'react';
import ApiCalls from "../api/index";
import "../App.css";
import moment from "moment";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  gridClasses,
} from "@mui/x-data-grid";
import XMLViewer from "react-xml-viewer";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { useEffect } from "react";
import { css } from "@emotion/react";
import BarLoader from "react-spinners/BarLoader";
import ReactPaginate from 'react-paginate';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  maxHeight: "400px",
  minWidth: "800px",
  overflowX: "auto !important",
  overflowY: "auto !important",
};

export default function JobsPage(props) {
  const [logs, setLogs] = React.useState([]);
  const [ boards, setBoards ] = useState([]);
  const [ customers, setCustomers ] = useState([]);
  const [remountComponent, setRemountComponent] = useState(0);
  const [ paginationData, setPaginationData ] = useState([]);
  const [ currentPage, setCurrentPage ] = useState(1);
  const [xmlData, setXmlData] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ isDeleteLoading, setIsDeleteLoading ] = useState(false);
  const [ deletingId, setDeletingId ] = useState(null);
  const [color, setColor] = useState("#03a9f4");
  const [ jobTitle, setJobTitle ] = useState('');
  const [ fromDate, setFromDate ] = useState('');
  const [ toDate, setToDate ] = useState('');
  const [ status, setStatus ] = useState('');
  const [ board, setBoard ] = useState('');
  const [ customer, setCustomer ] = useState('');
  const [, updateState] = React.useState();
  const customerId = localStorage.getItem('consumer_id'); 
  const [alert, setAlert] = useState({status: "", message: ""});  

  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 15;

  const forceUpdate = React.useCallback(() => updateState({}), []);

  const override = css`
  display: block;
  margin: 0 auto;
  border-color: #03a9f4;
`;

  useEffect(() => {
    searchJobs();
  }, [itemOffset, itemsPerPage]);

  useEffect(() => {
    ApiCalls.get(`/api/job-boards`).then((res) => {
      if (res) {
        const { data } = res.data;
        setBoards(data);
        forceUpdate();
      }
    });
  }, []);
  
  useEffect(() => {
    if((localStorage.getItem('token') && (!localStorage.getItem('consumer_id')))) {
      ApiCalls.get(`/api/customers`).then((res) => {
        if (res) {
          const { data } = res.data;
          setCustomers(data);
          forceUpdate();
        }
      });
    }
  }, [])

  const searchJobs = () => {
    setIsLoading(true); 
    var url = '';

    if((localStorage.getItem('token') && (!localStorage.getItem('consumer_id')))) {
      url = `/api/jobslogs?jobTitle=${jobTitle}&board=${board}&customer=${customerId ? customerId : customer}&status=${status}&fromDate=${fromDate}&toDate=${toDate}`;
    }else {
      url = `/api/jobs/${localStorage.getItem('consumer_id')}?jobTitle=${jobTitle}&board=${board}&customer=${customerId ? customerId : customer}&status=${status}&fromDate=${fromDate}&toDate=${toDate}`;
    }

    ApiCalls.get(url).then(res => {
      const { data } = res.data;
      //setBoards(data.data.boards);
      //setCustomers(data.customers);
      // if ( page === 1 ){
      setPaginationData(data); 
      const endOffset = itemOffset + itemsPerPage;
      setPageCount(Math.ceil(data.length / itemsPerPage));
      setIsLoading(false);
      setLogs(data.slice(itemOffset, endOffset));
      // } 
    })
    .catch(err => {
        setAlert({status: 'error', message: err.response.data.error});
    });
  }

  const handleModalOpen = (row) => {
    setXmlData(row);
    setOpen(true);
  };

  const handleJobPostedBoardDeleteBoard = (job_posted_board_id) => {
    if (window.confirm("Are you sure want to delete?")){    
      //Logic to delete the jobBoard
      setDeletingId(job_posted_board_id);
      setIsDeleteLoading(true);      
      ApiCalls.delete(`/api/jobs-posted-boards/delete/${job_posted_board_id}`).then((res) => {        
        console.log(res.status);
        if (res) {
          //const { data } = res.data;                    
          setIsDeleteLoading(false);          
          setLogs(logs.filter( job => job.job_posted_board_id !== job_posted_board_id));
          searchJobs();
          //searchJobs(1);
          setAlert({status: 'success', message: "Job posted board deleted successfully!"});  
          setTimeout(() => {
            setAlert({status: "", message: ""})
          }, 2000);          
        }
      });
    }    
  }
  

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
   const newOffset = (event.selected * itemsPerPage) % paginationData.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };


  const showModalWithResponseXML = (params) => {
    return (
      <strong>
        <a
          style={{ cursor: "pointer" }}
          onClick={() => handleModalOpen(params.row.responsePayload)}
        >
          View
        </a>
      </strong>
    );
  };
  const showModalWithRequestXML = (params) => {
    return (
      <strong>
        <a
          style={{ cursor: "pointer" }}
          onClick={() => handleModalOpen(params.row.requestPayload)}
        >
          View
        </a>
      </strong>
    );
  };

  function IsJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  function returnBeautifyString(str) {
    try {
      const a = JSON.parse(str);
      return JSON.stringify(a, null, 2);
    } catch (e) {
      return str;
    }
  }

  const handleClose = () => setOpen(false);
  const columns = [
    { field: "id", headerName: "ID", width: 100 },
    {
      field: "name",
      headerName: "Job board",
      width: 150,
    },
    {
      field: "timestamp",
      headerName: "Posted AT",
      width: 300,
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
    },
    {
      field: "requestPayload",
      headerName: "Request Payload",
      renderCell: showModalWithRequestXML,
      width: 150,
      sortable: false,
    },
    {
      field: "responsePayload",
      headerName: "Response Payload",
      renderCell: showModalWithResponseXML,
      width: 150,
      sortable: false,
    },
  ];

  function getStatusText(status) {
    if (status === "C") {
      return "Completed";
    } else if (status === "R") {
      return "Rejected";
    } else if (status === "P") {
      return "Pending";
    } else if (status === "A") {
      return "Active";
    }
  }
  function getRows(logs) {
    if (logs && logs.length > 0) {
      return logs.map(function (log) {
        return {
          id: log.log_id,
          name: log.name,
          status: getStatusText(log.status),
          timestamp: moment(log.request_timestamp).format(
            "DD-MMM-YYYY HH:mm:ss"
          ),
          requestPayload: log.request_payload,
          responsePayload: log.response_payload,
        };
      });
    } else {
      return [];
    }
  }

  const getCareerBuilderBoardStatusResponse = (transaction_did) => {    
    ApiCalls.get(`/api/status/career-builder/${transaction_did}`).then((res) => {
      if (res.status === 200) {
        setXmlData(res.data.data.response);
        setOpen(true);
      }
    });
  }

  const clearSearch = () => {    
    setIsLoading(true); 
    var url = '';
    setJobTitle('')
    setBoard('')
    setCustomer('')
    setFromDate('')
    setToDate('')
    var BoardDropDown = document.getElementById("board-select");
    BoardDropDown.selectedIndex = 0;
    var CustomerDropDown = document.getElementById("customer-select");
    CustomerDropDown.selectedIndex = 0;

    if((localStorage.getItem('token') && (!localStorage.getItem('consumer_id')))) {
      url = `/api/jobslogs`;
    }else {
      url = `/api/jobs/${localStorage.getItem('consumer_id')}`;
    }

    ApiCalls.get(url).then(res => {
      const { data } = res.data;
      setIsLoading(false);
      setLogs(data);
    })
    .catch(err => {
        setAlert({status: 'error', message: err.response.data.error});
    });
  }

  const fromDatePickerClick = () => {
    const inputDateElement = document.querySelector('#datepicker-autoclose1');
    inputDateElement.showPicker();
  }
  const toDatePickerClick = () => {
    const inputDateElement = document.querySelector('#datepicker-autoclose2');
    inputDateElement.showPicker();
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport
          csvOptions={{ fields: ["id", "name", "timestamp", "status"] }}
        />
      </GridToolbarContainer>
    );
  }
  return (
    <>
    
    <div class="row">
                            <div class="col-md-12">
                                <div class="panel panel-primary">
                                <div  style={{ display: "none", marginTop:"10px"}}  
                                              className={` 
                                                ${ alert.status =="success"? "alert alert-success show": "" } 
                                                ${ alert.status =="error"? "alert alert-danger show": "" }`}
                                        >
                                        <span>{alert.message}</span>
                                  </div>
                                    <div class="panel-heading">
                                        <h3 class="panel-title">Jobs Posted</h3>
                                    </div>
                                    <div class="panel-body" style={{paddingBottom:'5px'}}>
                                    <div class="row">
                                      <div class="col-md-12">
                                        <div class="row">
                                          <div class="col-md-4">
                                            <label>Job Title</label>
                                            <input type="text" 
                                            value={jobTitle} 
                                            onChange={ (e) => setJobTitle(e.target.value) }
                                            class="form-control" placeholder="" />
                                          </div>
                                          <div class="col-md-8">
                                            <div class="row">

                                            
                                          <div class="col-md-2">
                                            <label>Job Board</label>
                                            <select onChange={ (e) => setBoard(e.target.value) } class="form-control" placeholder="Select" id="board-select">
                                              <option value=''>Select</option>
                                              {boards.map( board => {
                                                return ( 
                                                    <option value={board.job_board_id} style={{textTransform:'capitalize'}}>{board.name}</option>
                                                )
                                              })}
                                            </select>
                                          </div>
                                          {!customerId && 
                                          <div class="col-md-2">
                                            <label>Customer</label>
                                            <select onChange={ (e) => setCustomer(e.target.value) } class="form-control" id="customer-select">
                                              <option value=''>Select</option>
                                              {customers.map( customer => {
                                                return ( 
                                                    <option value={customer.consumer_id} style={{textTransform:'capitalize'}}>{customer.username}</option>
                                                )
                                              })}
                                            </select>
                                          </div>
                                        }
                                          <div class="col-md-2" style={{display:'none'}}>
                                            <label>Status</label>
                                            <select onChange={ (e) => setStatus(e.target.value)   } class="form-control">
                                              <option value=''>Select</option>
                                              <option value='A'>Active</option>
                                              <option value='P'>Pending</option>
                                              <option value='R'>Rejected</option>
                                              <option value='C'>Completed</option>
                                            </select>
                                          </div>
                                          <div class="col-md-2">
                                            <label>From Date</label>
                                            <input type="date" style={{'cursor': 'pointer'}} onClick={fromDatePickerClick} value={fromDate} onChange={ (e) => setFromDate(e.target.value) } className="form-control" placeholder="From Date" id="datepicker-autoclose1"/>
                                          </div>
                                          <div class="col-md-2">
                                            <label>To Date</label>
                                            <input type="date" style={{'cursor': 'pointer'}} onClick={toDatePickerClick} value={toDate} onChange={ (e) => setToDate(e.target.value) } className="form-control" placeholder="To Date" id="datepicker-autoclose2" />
                                          </div>
                                          <div class="col-md-2">
                                            
                                            <button type="button" onClick={ () => { setItemOffset(0); setPaginationData(null); setPageCount(0); searchJobs(1);  setRemountComponent(Math.random()); } } style={{marginTop:'27px', width: '100%'}} class="btn btn-primary">Search</button>
                                          </div>
                                          <div class="col-md-2">
                                            
                                            <button type="button" onClick={ () => { clearSearch() } } style={{marginTop:'27px'}} class="btn btn-primary">Clear</button>
                                          </div>
                                          </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    </div>
                                    <div class="panel-body">
                                    <BarLoader color={color} loading={isLoading} css={override} size={550} />
                                      { !isLoading && 
                                        <div class="row">
                                            <div class="col-xs-12">
                                                <div class="">
                                                <table id="datatable" class="table table-striped table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>Job Title</th>  
                                                                { !localStorage.getItem('consumer_id') &&                                                               
                                                                  <th>Customer Name</th>                    
                                                                }  
                                                                <th>Board Name</th>                                            
                                                                <th class="text-center">Posted Date</th>
                                                                <th>Request Payload</th>
                                                                <th>Response Payload</th>                                                                                                                         
                                                                <th class="text-center">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                          { logs.length < 1 && 
                                                            <tr>
                                                                <td colSpan={7} className='text-center'>No Jobs found.</td>
                                                            </tr>
                                                          }
                                                        {logs.map( log => {
                                                          return ( 
                                                            <>
                                                            <tr>
                                                                <td>{log.title}</td>
                                                                { !localStorage.getItem('consumer_id') &&                                                                
                                                                  <td style={{textTransform:'capitalize'}}>{log.username}</td>
                                                                }
                                                                <td style={{textTransform:'capitalize'}}>{log.board_name}</td>
                                                                <td class="text-center">{moment(log.updated_at).format("MMM DD, YYYY")}</td>                                                             
                                                                <td class="text-center" style={{width:'150px', cursor: 'pointer'}}><a onClick={() => handleModalOpen(log.request_payload)} title="View Request Payload"><i class="ti-eye"></i></a></td>
                                                                <td class="text-center" style={{width:'150px', cursor: 'pointer'}}>{log.board_slug == 'career-builder'? <a onClick={() => getCareerBuilderBoardStatusResponse(log.transaction_did)} title="View Response Payload"><i class="ti-eye"></i></a> : <a onClick={() => handleModalOpen(log.response_payload)}  title="View Response Payload"><i class="ti-eye"></i></a> }</td>
                                                                <td class="text-center" style={{width:'150px', cursor: 'pointer'}}>                                                                  
                                                                  <a style={{ marginLeft:"10px"}} onClick={() => handleJobPostedBoardDeleteBoard(log.jobs_to_board_id)} title="Delete"><i class="ti-trash"></i> {isDeleteLoading && deletingId===log.jobs_to_board_id?'Deleting...':''}</a>
                                                                </td>
                                                            </tr>
                                                            </>
                                                          )
                                                        })}
                                                        </tbody>
                                                    </table>                                                    
                                                </div>
                                            </div>
                                        </div>
                                      }
                                        <div key={remountComponent}>
                                          <ReactPaginate
                                            className="pagination pull-right"
                                              breakLabel="..."
                                              nextLabel="next >"
                                              onPageChange={handlePageClick}
                                              pageRangeDisplayed={5}
                                              pageCount={pageCount}
                                              previousLabel="< previous"
                                              activeClassName="active"
                                              renderOnZeroPageCount={null}
                                                          />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
    
    <div className="table-responsive" style={{ height: "auto", display:'none' }}>      
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {IsJsonString(xmlData) ? (
            <pre>{returnBeautifyString(xmlData)}</pre>
          ) : (
            <XMLViewer xml={xmlData} />
          )}
        </Box>
      </Modal>
    </div>
    </>
  );
}
