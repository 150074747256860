import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Header from "../../components/header";
import ApiCalls from "../../api/index";
import "../../App.css";
import ReCAPTCHA from "react-google-recaptcha";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

export default function HomePage(props) {

  const [customer, setCustomer] = useState({
    name: "",
    email: "",
    password: "",
    subscription_end_date: "",
  });

  const [alert, setAlert] = useState({status: "", message: ""});
  const [ messageSent, setMessageSent ] = useState(false);

  const TEST_SITE_KEY = "6LfMf_AgAAAAAK2VfEHmIBHE5iLlhC-_Ue2W2oZa";
  const DELAY = 1500;

  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required("Name is required"),
    email: Yup.string().email('Invalid email format').required("Email is required"),
    phone: Yup.string().trim().required("Phone is required"),
    message: Yup.string().trim().required("Message is required")    
  });

  const {
    register,
    handleSubmit,
    setValue: setValue1,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange"    
  });

  useEffect(() => {

  }, []);


  const handleChangeCustomer = (event, fieldName) => {

    if (fieldName === "name")
    setCustomer((prevState) => {
      return { ...prevState, name: event.target.value };
    });

    if (fieldName === "email")
      setCustomer((prevState) => {
        return { ...prevState, email: event.target.value };
      });
   

    if (fieldName === "phone")
      setCustomer((prevState) => {
        return { ...prevState, phone: event.target.value };
      });

    if (fieldName === "message")
      setCustomer((prevState) => {
        return { ...prevState, message: event.target.value };
      });
  }; 

  const onSubmit = async (data) => {
    //setIsLoading(true);
    console.log('data', data);
    handleSubmitOld(data);
  };

  // sign up
  const handleSubmitOld = (data) => {
    // console.log(customer);
    ApiCalls.post(`/api/contact-us/store`, data).then((res) => {
      if (res) {
        const { data } = res.data;
        setMessageSent(true);
        //navigate("/login", { state: "You have been successfully signed up. Please login." });
        setAlert({status: 'success', message: res.data.message});
      }
      else {
        setAlert({status: 'error', message: res.data.message});
      }        
    });
   
  };


  return (
<>
    <Header/>
<div class="coming-soon">
<div className="container" style={{marginTop: '30px', paddingTop: '20px'}}>
<div class="row">
  <div
    class="panel panel-color panel-primary panel-pages"
    style={{ width: "40%", marginLeft: "auto", marginRight: "auto", marginTop: "10%" }}
  >
    <div class="panel-body">
      <h3 class="text-center m-t-0 m-b-30" style={{display:'none'}}>
        {" "}
        <span class="">
          <img src="assets/images/big/bigb.png" alt="logo" style={{height :"auto", maxWidth : "170px"}} />
        </span>
      </h3>
      <h4 class="text-muted text-center m-t-0">
        <b>Request a Demo</b>
      </h4>
      <form class="form-horizontal m-t-20" onSubmit={handleSubmit(onSubmit)}>
      <div  style={{ display: "none"}}  
                    className={` 
                      ${ alert.status =="success"? "alert alert-success show": "" } 
                      ${ alert.status =="error"? "alert alert-danger show": "" }`}
              >
                <span>{alert.message}</span>
              </div>

   { messageSent === false && 
   <>
              <div class="form-group">
          <div class="col-xs-12">
            <input
              class="form-control" 
              style={{fontSize:'14px'}}
              type="text"
              required=""
              placeholder="Name"
              name="name"
              id="name"
              className={`form-control input-group m-b-0 ${
                errors.name ? "is-invalid" : ""
              }`}
              {...register("name")} 
            />
          </div>
        </div>
        <div class="form-group">
          <div class="col-xs-12">
            <input
              class="form-control" 
              style={{fontSize:'14px'}}
              type="email"
              required=""
              placeholder="Email"
              name="email"
              id="email"
              className={`form-control input-group m-b-0 ${
                errors.email ? "is-invalid" : ""
              }`}
              {...register("email")} 
            />
          </div>
        </div>

        <div class="form-group">
          <div class="col-xs-12">
            <input
              class="form-control" 
              style={{fontSize:'14px'}}
              type="text"
              required=""
              placeholder="Phone"
              name="phone"
              id="phone"
              className={`form-control input-group m-b-0 ${
                errors.phone ? "is-invalid" : ""
              }`}
              {...register("phone")} 
            />
          </div>
        </div>

        <div class="form-group">
          <div class="col-xs-12">
            <textarea
              class="form-control" 
              style={{fontSize:'14px', height:'175px'}}              
              required=""
              placeholder="Message"
              name="message"
              id="message"
              className={`form-control input-group m-b-0 ${
                errors.message ? "is-invalid" : ""
              }`}
              {...register("message")} 
            ></textarea>
          </div>
        </div>
        
        <div class="form-group">
            <div class="col-xs-12">
                <ReCAPTCHA
            style={{ display: "inline-block", width:'100%' }}
            theme="light"
            size="large"
            sitekey={TEST_SITE_KEY}
            
            
          />
                
                </div>

              </div>
        <div class="form-group text-center m-t-20">
          <div class="col-xs-12">
            {" "}
            <button type="submit"
              class="btn btn-primary w-md waves-effect waves-light"
              style={{fontSize:'17px'}}                            
            >
              Submit
            </button>
          </div>
        </div>
        </>
        }
        <div class="form-group m-t-30 m-b-0">
          <div class="col-sm-7"></div>
          <div class="col-sm-5 text-right"> </div>
        </div>
      </form>
    </div>
  </div>
</div>
</div>
</div>
</>


  );
}
